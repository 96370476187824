<template>
  <el-popover
    placement="bottom"
    title="Tag"
    width="200"
    trigger="click"
    content="this is content, this is content, this is content"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <i
      slot="reference"
      class="fas fa-tag tag-icon"
    />
  </el-popover>
</template>

<script>
export default {
  name: 'TagBtn',
};
</script>

<style scoped>
.tag-icon {
  font-size: 16px;
  color: #bfc1c5;
}

.tag-icon:hover {
  color: #525252;
  cursor: pointer;
}

</style>
